(function ($) {
	$(document).ready(function () {
		if (
			$("body").hasClass("page-home") ||
			$("body").hasClass("page-register-your-interest") ||
			$("body").hasClass("page-template-template-landing")
		) {
			// UK Postcode Regex

			$.validator.addMethod("postcodeuk", function (value, element) {
				return (
					this.optional(element) ||
					/^((([A-PR-UWYZ][0-9])|([A-PR-UWYZ][0-9][0-9])|([A-PR-UWYZ][A-HK-Y][0-9])|([A-PR-UWYZ][A-HK-Y][0-9][0-9])|([A-PR-UWYZ][0-9][A-HJKSTUW])|([A-PR-UWYZ][A-HK-Y][0-9][ABEHMNPRVWXY]))\s?([0-9][ABD-HJLNP-UW-Z]{2})|(GIR)\s?(0AA))$/i.test(
						value
					)
				);
			});

			// Next Step Button

			$(".js-next").click(function () {
				const form = $("#register-step-form");

				// Form Validation

				$(form).validate({
					errorClass: "js-error",
					errorElement: "span",
					errorPlacement: function (error, element) {
						error.addClass("mt-2 text-base text-pink text-opacity-80");
						if ("b2bcompany" == element.attr("name")) {
							error.appendTo("#js-b2b");
						} else if ("companystructure" == element.attr("name")) {
							error.appendTo("#js-structure");
						} else if ("workshopinterest[]" == element.attr("name")) {
							error.appendTo("#js-interest");
						} else if ("consent" == element.attr("name")) {
							error.appendTo("#js-consent");
						} else if ("shared" == element.attr("name")) {
							error.appendTo("#js-shared");
						} else {
							error.insertAfter(element);
						}
					},
					rules: {
						companyname: {
							required: true,
						},
						businesstype: {
							required: true,
						},
						postcode: {
							required: true,
							postcodeuk: true,
						},
						contactname: {
							required: true,
						},
						jobtitle: {
							required: true,
						},
						emailaddress: {
							required: true,
							email: true,
						},
						b2bcompany: {
							required: true,
						},
						companystructure: {
							required: true,
						},
						companynumber: {
							required: true,
						},
						datebusiness: {
							required: true,
						},
						"workshopinterest[]": {
							required: true,
						},
						consent: {
							required: true,
						},
						shared: {
							required: true,
						},
					},
					messages: {
						companyname: "Please enter company name",
						businesstype: "Please enter type of business",
						postcode: {
							required: "Please enter UK Postcode",
							postcodeuk: "Please enter valid UK Postcode",
						},
						contactname: "Please enter contact name",
						jobtitle: "Please enter job title",
						emailaddress: {
							required: "Please enter email address",
						},
						b2bcompany: "Please select if you are a B2B Company",
						companystructure: "Please select company structure",
						companynumber: "Please enter company number",
						datebusiness: "Please enter date business established",
						"workshopinterest[]":
							"Please select a workshop subject of interest",
						consent:
							"Please consent to us processing your data for these purposes",
						shared: "Please confirm the information can be shared",
					},
				});

				if (true === form.valid()) {
					if ($("#step-1").is(":visible")) {
						$("#register-step-form .step").text("2");
						$("#step-1").removeClass("block").addClass("hidden");
						$("#step-2").removeClass("hidden").addClass("block");
					} else if ($("#step-2").is(":visible")) {
						$("#register-step-form .step").text("3");
						$("#step-2").removeClass("block").addClass("hidden");
						$("#step-3").removeClass("hidden").addClass("block");
					} else if ($("#step-3").is(":visible")) {
						$("#register-step-form .step").text("4");
						$("#step-3").removeClass("block").addClass("hidden");
						$("#step-4").removeClass("hidden").addClass("block");
					}
				}
			});

			// Previous Step Button

			$(".js-prev").click(function () {
				if ($("#step-2").is(":visible")) {
					$("#register-step-form .step").text("1");
					$("#step-2").removeClass("block").addClass("hidden");
					$("#step-1").removeClass("hidden").addClass("block");
				} else if ($("#step-3").is(":visible")) {
					$("#register-step-form .step").text("2");
					$("#step-3").removeClass("block").addClass("hidden");
					$("#step-2").removeClass("hidden").addClass("block");
				} else if ($("#step-4").is(":visible")) {
					$("#register-step-form .step").text("3");
					$("#step-4").removeClass("block").addClass("hidden");
					$("#step-3").removeClass("hidden").addClass("block");
				}
			});

			$("body").on("submit", "#register-step-form", function (e) {
				e.preventDefault();

				const form = $(this);

				$.ajax({
					url: form.attr("action"),
					data: form.serialize(),
					type: form.attr("method"),
					action: "register_step_enquiry",
					success: function (data) {
						form.fadeOut();
						form.empty();
						form.append(data);
						form.fadeIn();
					},
					error: function (xhr, status, error) {
						console.log(xhr);
						alert(status);
						alert(error);
					},
				}).done(function () {});
				return false;
			});
		}
	});
})(jQuery);
