const getOptions = document.querySelectorAll(".form-select");

const numberOfEmployees = document.getElementById("numberofemployees");
const turnover = document.getElementById("turnover");

const empty = document.querySelector(".empty");

const eligibilityForm = document.querySelector(".js-check");

if (eligibilityForm) {
	eligibilityForm.addEventListener("click", (evt) => {
		evt.preventDefault();

		const numberOfEmployeesValue =
			numberOfEmployees.options[numberOfEmployees.selectedIndex].value;
		const turnoverValue = turnover.options[turnover.selectedIndex].value;

		if ("" == numberOfEmployeesValue || "" == turnoverValue) {
			empty.textContent = "Please select options";
		} else if (
			numberOfEmployeesValue.includes("ineligible") ||
			turnoverValue.includes("ineligible")
		) {
			empty.textContent = "Sorry not eligable";
		} else if (
			"eligible" == numberOfEmployeesValue &&
			"eligible" == turnoverValue
		) {
			empty.textContent = "Eligable";
		}

		console.log(numberOfEmployeesValue);
	});
}
