(function ($) {
	if ($("body").hasClass("page-resources-area")) {
		$.validator.addMethod(
			"strong_password",
			function (value, element) {
				let password = value;
				if (
					!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%&])(.{8,20}$)/.test(
						password
					)
				) {
					return false;
				}
				return true;
			},
			function (value, element) {
				let password = $(element).val();
				if (!/^(.{8,20}$)/.test(password)) {
					return "Password must be between 8 to 20 characters long.";
				} else if (!/^(?=.*[A-Z])/.test(password)) {
					return "Password must contain at least one uppercase.";
				} else if (!/^(?=.*[a-z])/.test(password)) {
					return "Password must contain at least one lowercase.";
				} else if (!/^(?=.*[0-9])/.test(password)) {
					return "Password must contain at least one digit.";
				} else if (!/^(?=.*[@#$%&])/.test(password)) {
					return "Password must contain special characters from @#$%&.";
				}
				return false;
			}
		);

		function passwordToggle() {
			// Password Toggle
			const togglePassword =
				document.querySelector("#togglePasswordLog") ?? null;
			const password = document.querySelector("#password_log");

			if (togglePassword) {
				togglePassword.addEventListener("click", function () {
					// toggle the type attribute
					const type =
						password.getAttribute("type") === "password" ? "text" : "password";
					password.setAttribute("type", type);

					// toggle the icon
					this.classList.toggle("opacity-20");
				});
			}
		}

		// Redirect to Lost Password Form
		$("body").on("click", ".lost-password", function (e) {
			e.preventDefault();
			$(".form-type.login").addClass("hidden");
			$(".form-type.password").removeClass("hidden");
		});

		function formValidation() {
			$("#member-login").validate({
				debug: true,
				errorClass: "js-error",
				errorElement: "span",
				errorPlacement: function (error, element) {
					error.addClass("mt-2 text-base text-pink text-opacity-80");
					error.insertAfter(element);
				},
				rules: {
					username: {
						required: true,
					},
					password: {
						required: true,
					},
				},
				messages: {
					username: "Please enter a username",
					password: "Please enter a password",
				},
			});
		}

		function dataLayer() {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "member_form_submission",
				formType: "Login",
			});
		}

		function formSubmit() {
			$("body").on("submit", "#member-login", function (e) {
				e.preventDefault();

				var form = $(this);
				var message = $(".member-login-message");

				$.ajax({
					url: form.attr("action"),
					data: form.serialize(),
					type: form.attr("method"),
					action: "member_login",
					success: function (data) {
						if (data.success) {
							form.addClass("success");
							window.location.href = data.data.redirect;
							dataLayer();
						} else {
							message.html(
								'<div class="text-lg mb-6 text-center xl:text-xl xl:mb-8">' +
									data.data.message +
									"</div>"
							);
							form
								.find('input[type="submit"]')
								.removeAttr("disabled")
								.val("Login");
						}
					},
					error: function (xhr, status, error) {
						console.log(xhr);
						alert(status);
						alert(error);
					},
				}).done(function () {});
				return false;
			});
		}

		passwordToggle();
		formValidation();
		formSubmit();
	}
})(jQuery);
