( function( $ ) {

	function formValidation() {

		$( '#enquiry-form' ).validate({
			debug: true,
			errorClass: 'js-error',
			errorElement: 'span',
			errorPlacement: function( error, element ) {
				error.addClass( 'mt-2 text-base text-pink text-opacity-80' );
				if ( 'agree' == element.attr( 'name' ) ) {
					error.appendTo( '#js-valid' );
					return;
				} else {
					error.insertAfter( element );
				}
			},
			rules: {
				fullname: {
					required: true
				},
				emailaddress: {
					required: true,
					email: true
				},
				message: {
					required: true
				},
				agree: {
					required: true
				}
			},
			messages: {
				fullname: "Please enter full name",
				emailaddress: "Please enter email address",
				message: "Please explain how we can help",
				agree: "Please agree to be contacted"
			}
		});

	}

	function formSubmit() {

		$( 'body' ).on( 'submit', '#enquiry-form', function( e ) {
			e.preventDefault();

			var form = $( this );

			$.ajax({
				url: form.attr( 'action' ),
				data: form.serialize(),
				type: form.attr( 'method' ),
				action: 'contact_enquiry',
				success: function( data ) {
					form.fadeOut();
					form.empty();
					form.append( data );
					form.fadeIn();
				},
				error: function( xhr, status, error ) {
					console.log( xhr );
					alert( status );
					alert( error );
				}
			}).done( function() {});
			return false;
		});

	}

	formValidation();
	formSubmit();

}( jQuery ) );
