(function ($) {
	if ($("body").hasClass("page-resources-area")) {
		function formValidation() {
			$("#member-password").validate({
				debug: true,
				errorClass: "js-error",
				errorElement: "span",
				errorPlacement: function (error, element) {
					error.addClass("mt-2 text-base text-pink text-opacity-80");
					error.insertAfter(element);
				},
				rules: {
					username: {
						required: true,
					},
				},
				messages: {
					username: "Please enter a username or email address",
				},
			});
		}

		function formSubmit() {
			$("body").on("submit", "#member-password", function (e) {
				e.preventDefault();

				var form = $(this);
				var message = $(".member-password-message");

				$.ajax({
					url: form.attr("action"),
					data: form.serialize(),
					type: form.attr("method"),
					action: "member_password",

					success: function (data) {
						console.log(data);
						if (data.success) {
							message.html(
								'<div class="text-lg mb-6 text-center xl:text-xl xl:mb-8">' +
									data.data.message +
									"</div>"
							);
						} else {
							// console.log(data);
							message.html(
								'<div class="text-lg mb-6 text-center xl:text-xl xl:mb-8">' +
									data.data.message +
									"</div>"
							);
						}
					},
					error: function (xhr, status, error) {
						console.log(xhr);
						alert(status);
						alert(error);
					},
				}).done(function () {});
				return false;
			});
		}

		formValidation();
		formSubmit();
	}
})(jQuery);
