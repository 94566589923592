( function( $ ) {

	function formValidation() {

		$( '#join-us-sign-up-form' ).validate({
			debug: true,
			errorClass: 'js-error',
			errorElement: 'span',
			errorPlacement: function( error, element ) {
				error.addClass( 'mt-2 text-base text-pink text-opacity-80' );
				if ( 'consent' == element.attr( 'name' ) ) {
					error.appendTo( '#js-consent' );
					return;
				} else {
					error.insertAfter( element );
				}
			},
			rules: {
				fullname: {
					required: true
                },
                companyname: {
					required: true
				},
				emailaddress: {
					required: true,
					email: true
				},
				postcode: {
                    required: true
                },
				consent: {
					required: true
				}
			},
			messages: {
				fullname: "Please enter your name",
				emailaddress: "Please enter your email address",
                companyname: "Please enter your company name",
                consent: "Please agree to be contacted",
                postcode: "Please enter a valid postcode"
			}
		});

	}

	function formSubmit() {

		$( 'body' ).on( 'submit', '#join-us-sign-up-form', function( e ) {
			e.preventDefault();

			var form = $( this );

			$.ajax({
				url: form.attr( 'action' ),
				data: form.serialize(),
				type: form.attr( 'method' ),
				action: 'ready_to_join_us_enquiry',
				success: function( data ) {
					form.fadeOut();
					form.empty();
					form.append( data );
                    form.fadeIn();
                   
				},
				error: function( xhr, status, error ) {
					console.log( xhr );
					alert( status );
					alert( error );
				}
			}).done( function() {});
			return false;
		});

	}

	formValidation();
	formSubmit();

}( jQuery ) );
