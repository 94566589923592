(function ($) {
	$(document).ready(function () {
		if ($("body").hasClass("page-qualify")) {
			// UK Postcode Regex

			$.validator.addMethod(
				"postcodeuk",
				function (value, element) {
					return (
						this.optional(element) ||
						/^((([A-PR-UWYZ][0-9])|([A-PR-UWYZ][0-9][0-9])|([A-PR-UWYZ][A-HK-Y][0-9])|([A-PR-UWYZ][A-HK-Y][0-9][0-9])|([A-PR-UWYZ][0-9][A-HJKSTUW])|([A-PR-UWYZ][A-HK-Y][0-9][ABEHMNPRVWXY]))\s?([0-9][ABD-HJLNP-UW-Z]{2})|(GIR)\s?(0AA))$/i.test(
							value
						)
					);
				},
				"Please enter valid UK Postcode"
			);

			$(".accordion-item").each(function () {
				// If business is trading

				$('input:radio[name="trading"]').change(function () {
					if ($(this).is(":checked") && $(this).hasClass("active-trading")) {
						$(".datebusiness").removeClass("hidden").addClass("flex");
					} else {
						$(".datebusiness").addClass("hidden").removeClass("flex");
					}
				});

				// Check State Aid

				$('input:radio[name="stateaid"]').change(function () {
					if ($(this).is(":checked") && "Yes" == $(this).val()) {
						$(".stateaid").removeClass("hidden").addClass("block");
					} else {
						$(".stateaid").addClass("hidden").removeClass("block");
					}
				});

				// Next Accordion

				$(".js-next").click(function () {
					// Form Validation

					const form = $("#qualify-form");

					$(form).validate({
						errorClass: "js-error",
						errorElement: "span",
						errorPlacement: function (error, element) {
							error.addClass("mt-2 text-base text-pink text-opacity-80");
							if ("companystructure" == element.attr("name")) {
								error.insertAfter(".form-radio-companystructure");
							} else if ("ownanotherbusiness" == element.attr("name")) {
								error.insertAfter(".form-radio-ownanotherbusiness");
							} else if ("anotherbusiness" == element.attr("name")) {
								error.insertAfter(".form-radio-anotherbusiness");
							} else if ("turnoverless" == element.attr("name")) {
								error.insertAfter(".form-radio-turnoverless");
							} else if ("trading" == element.attr("name")) {
								error.insertAfter(".form-radio-trading");
							} else if ("stateaid" == element.attr("name")) {
								error.insertAfter(".form-radio-stateaid");
							} else if ("consent" == element.attr("name")) {
								error.insertAfter(".form-radio-consent");
							} else if ("shared" == element.attr("name")) {
								error.insertAfter(".form-radio-shared");
							} else {
								error.insertAfter(element);
							}
						},
						rules: {
							companyname: {
								required: true,
							},
							tradingname: {
								required: true,
							},
							tradingaddress1: {
								required: true,
							},
							city: {
								required: true,
							},
							postcode: {
								postcodeuk: true,
								required: true,
							},
							vatregnumber: {
								required: true,
							},
							contactname: {
								required: true,
							},
							jobtitle: {
								required: true,
							},
							emailaddress: {
								required: true,
							},
							businessactivities: {
								required: true,
							},
							companystructure: {
								required: true,
							},
							companynumber: {
								required: true,
							},
							employees: {
								required: true,
							},
							ownanotherbusiness: {
								required: true,
							},
							anotherbusiness: {
								required: true,
							},
							turnoverless: {
								required: true,
							},
							turnover: {
								required: true,
							},
							balancesheet: {
								required: true,
							},
							datebusiness: {
								required: true,
							},
							stateaid: {
								required: true,
							},
							bodyaid: {
								required: true,
							},
							valueassistance: {
								required: true,
							},
							dateassistance: {
								required: true,
							},
							workshopinterest1: {
								required: true,
							},
							workshopinterest2: {
								required: true,
							},
							workshopinterest3: {
								required: true,
							},
							consent: {
								required: true,
							},
							shared: {
								required: true,
							},
						},
						messages: {
							companyname: "Please enter company name",
							tradingname: "Please enter trading name",
							tradingaddress1: "Please enter trading address",
							city: "Please enter city",
							postcode: {
								required: "Please enter UK Postcode",
								postcodeuk: "Please enter valid UK Postcode",
							},
							vatregnumber: "Please enter VAT Registration Number",
							contactname: "Please enter contact name",
							jobtitle: "Please enter job title",
							emailaddress: {
								required: "Please enter email address",
							},
							businessactivities:
								"Please enter brief description of business activities",
							companystructure: "Please select company structure",
							companynumber: "Please enter company number",
							employees: "Please enter number of employees",
							ownanotherbusiness:
								"Please select if you own 25% of another business",
							anotherbusiness:
								"Please select if another business owns more than 25% of your business",
							turnoverless: "Please select if your turnover is less than €50m",
							turnover: "Please state your turnover",
							trading: "Please state how long you have been trading for",
							datebusiness: "Please enter date business established",
							stateaid: "Please select if you received State Aid previously",
							bodyaid: "Please state body providing assistance/aid",
							valueassistance: "Please state value of assistance",
							dateassistance: "Please enter date and month of assistance",
							workshopinterest1: "Please select first subject of interest",
							workshopinterest2: "Please select second subject of interest",
							workshopinterest3: "Please select third subject of interest",
							consent:
								"Please consent to us processing your data for these purposes",
							shared: "Please confirm the information can be shared",
						},
					});

					if (true === form.valid()) {
						$(this).closest(".accordion-item").removeClass("active");
						$(this).closest(".accordion-item__content").slideUp();
						$(this).closest(".accordion-item").next().addClass("active");
						$(this)
							.closest(".accordion-item")
							.next()
							.find(".accordion-item__content")
							.slideDown();
					}
				});

				// Previous Accordion

				$(this)
					.find(".js-prev")
					.on("click", function () {
						$(this).closest(".accordion-item").removeClass("active");
						$(this).closest(".accordion-item__content").slideUp();
						$(this).closest(".accordion-item").prev().addClass("active");
						$(this)
							.closest(".accordion-item")
							.prev()
							.find(".accordion-item__content")
							.slideDown();
					});
			});

			// Add another Stateaid Body

			$(".js-add").click(function () {
				var html = "";
				html +=
					'<div class="stateaid__row grid mt-4 mb-8 md:grid-cols-4 gap-4 md:gap-y-6">';
				html += '<div class="flex flex-col space-y-2">';
				html +=
					'<label for="bodyaid" class="form-label flex-grow">Body providing the assistance/aid</label>';
				html +=
					'<input type="text" name="bodyaid[]" id="bodyaid" class="form-input">';
				html += "</div>";
				html += '<div class="flex flex-col space-y-2">';
				html +=
					'<label for="valueassistance" class="form-label">Value of assistance (calculating the Gross Grant Equivalent) in EURO</label>';
				html +=
					'<input type="text" name="valueassistance[]" id="valueassistance[]" class="form-input" placeholder="£">';
				html += "</div>";
				html += '<div class="flex flex-col space-y-2">';
				html +=
					'<label for="dateassistance" class="form-label flex-grow">Date of assistance</label>';
				html +=
					'<input type="date" name="dateassistance[]" id="dateassistance" class="form-input" placeholder="__/__">';
				html += "</div>";

				html += '<div class="flex flex-col justify-end">';
				html += '<button type="button" class="btn btn--blue-lt js-remove">';
				html += "Remove Row";
				html += "</button>";
				html += "</div>";
				html += "</div>";
				$(".stateaid__additional").append(html);

				const totalStateAid = $(".stateaid__row").length;

				if ((totalStateAid) => 2) {
					$(".stateaid__total").removeClass("hidden");
				}
			});

			// Remove Stateaid Body

			$(document).on("click", ".js-remove", function () {
				$(this).closest(".stateaid__row").remove();

				const totalStateAid = $(".stateaid__row").length;

				if (totalStateAid <= 2) {
					$(".stateaid__total").addClass("hidden");
				}
			});

			$("body").on("submit", "#qualify-form", function (e) {
				e.preventDefault();

				const form = $(this);

				$.ajax({
					url: form.attr("action"),
					data: form.serialize(),
					type: form.attr("method"),
					action: "qualify_enquiry",

					success: function (data) {
						form.fadeOut();
						form.empty();
						form.append(data);
						form.fadeIn();
					},
					error: function (xhr, status, error) {
						console.log(xhr);
						alert(status);
						alert(error);
					},
				}).done(function () {});
				return false;
			});
		}
	});
})(jQuery);
