(function ($) {
	if ($("body").hasClass("page-member-account")) {
		$.validator.addMethod(
			"strong_password",
			function (value, element) {
				let password = value;
				if (
					!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%&])(.{8,20}$)/.test(
						password
					)
				) {
					return false;
				}
				return true;
			},
			function (value, element) {
				let password = $(element).val();
				if (!/^(.{8,20}$)/.test(password)) {
					return "Password must be between 8 to 20 characters long.";
				} else if (!/^(?=.*[A-Z])/.test(password)) {
					return "Password must contain at least one uppercase.";
				} else if (!/^(?=.*[a-z])/.test(password)) {
					return "Password must contain at least one lowercase.";
				} else if (!/^(?=.*[0-9])/.test(password)) {
					return "Password must contain at least one digit.";
				} else if (!/^(?=.*[@#$%&])/.test(password)) {
					return "Password must contain special characters from @#$%&.";
				}
				return false;
			}
		);
		function passwordToggle() {
			// Password Toggle
			const togglePassword =
				document.querySelector("#togglePasswordAcc") ?? null;
			const password = document.querySelector("#password_acc");

			if (togglePassword) {
				togglePassword.addEventListener("click", function () {
					// toggle the type attribute
					const type =
						password.getAttribute("type") === "password" ? "text" : "password";
					password.setAttribute("type", type);

					// toggle the icon
					this.classList.toggle("opacity-20");
				});
			}
		}
		passwordToggle();
	}
})(jQuery);
