import $ from "jquery";
import Swiper, {
	EffectFade,
	Lazy,
	Navigation,
	Pagination,
	Parallax,
} from "swiper";

$(function () {
	Swiper.use([EffectFade, Lazy, Navigation, Pagination, Parallax]);

	window.ResizeObserver = undefined;

	const defaultSlider = new Swiper(".slider", {
		containerModifierClass: "slider-",
		wrapperClass: "slider-wrapper",
		slideClass: "slide",
		slideActiveClass: "slide-active",
		slideVisibleClass: "slide-visible",
		navigation: {
			nextEl: ".slider-button-next",
			prevEl: ".slider-button-prev",
			disabledClass: "slider-button-disabled",
			hiddenClass: "slider-button-hidden",
		},
		pagination: {
			modifierClass: "slider-pagination-",
			el: ".slider-pagination",
			bulletClass: "slider-pagination-bullet",
			bulletActiveClass: "slider-pagination-bullet-active",
			type: "bullets",
		},
		autoplay: {
			delay: 8000,
		},
		parallax: true,
		effect: "fade",
		fadeEffect: {
			crossFade: true,
		},
	});

	const resourcesSlider = () => {
		let sliders = document.querySelectorAll(".slider-resources");
		let prevArrow = document.querySelectorAll(".slider-resource-prev");
		let nextArrow = document.querySelectorAll(".slider-resource-next");

		sliders.forEach((slider, index) => {
			const resourceSlider = new Swiper(slider, {
				containerModifierClass: "slider-resources-",
				wrapperClass: "slider-resources-wrapper",
				slideClass: "slide-resources",
				slideActiveClass: "slide-resources-active",
				slideVisibleClass: "slide-resources-visible",
				navigation: {
					nextEl: nextArrow[index],
					prevEl: prevArrow[index],
					disabledClass: "slider-resource-disabled",
					hiddenClass: "slider-resource-hidden",
				},
				slidesPerView: 1,
				spaceBetween: 12,
				breakpoints: {
					768: {
						slidesPerView: 2,
					},
					1024: {
						slidesPerView: 3,
					},
					1366: {
						slidesPerView: 4,
						spaceBetween: 24,
					}
				},
			});
		});
	};
	resourcesSlider();
});
