( function( $ ) {

	function formValidation() {

		$( '#event-form' ).validate({
			debug: true,
			errorClass: 'js-error',
			errorElement: 'span',
			errorPlacement: function( error, element ) {
				error.addClass( 'mt-2 text-base text-pink text-opacity-80' );
				if ( 'socialmedia' == element.attr( 'name' ) ) {
					error.appendTo( '#js-social' );
					return;
				} else if ( 'agree' == element.attr( 'name' ) ) {
					error.appendTo( '#js-agree' );
					return;
				} else {
					error.insertAfter( element );
				}
			},
			rules: {
				seminar: {
					required: true
				},
				seminardate: {
					required: true
				},
				fullname: {
					required: true
				},
				emailaddress: {
					required: true,
					email: true
				},
				bookingprocess: {
					required: true
				},
				qualitycontent: {
					required: true
				},
				overallvalue: {
					required: true
				},
				qualitypresenter: {
					required: true
				},
				expectations: {
					required: true
				},
				seminarfeedback: {
					required: true
				},
				agree: {
					required: true
				}
			},
			messages: {
				seminar: 'Please enter seminar you attended',
				seminardate: 'Please enter seminar date',
				fullname: 'Please enter full name',
				emailaddress: 'Please enter email address',
        bookingprocess: 'Please rate booking process',
        qualitycontent: 'Please rate quality of the content',
        overallvalue: 'Please rate overall value to your organisation',
        qualitypresenter: 'Please rate quality of the presenter',
        expectations: 'Please select how your expectations were',
				seminarfeedback: 'Please tell us what you thought about the seminar',
				agree: 'Please agree to be contacted'
			}
		});

	}

	function formSubmit() {

		$( 'body' ).on( 'submit', '#event-form', function( e ) {
			e.preventDefault();

			var form = $( this );

			$.ajax({
				url: form.attr( 'action' ),
				data: form.serialize(),
				type: form.attr( 'method' ),
				action: 'contact_enquiry',
				success: function( data ) {
					form.fadeOut();
					form.empty();
					form.append( data );
					form.fadeIn();
				},
				error: function( xhr, status, error ) {
					console.log( xhr );
					alert( status );
					alert( error );
				}
			}).done( function() {});
			return false;
		});

	}

	formValidation();

	formSubmit();

}( jQuery ) );
