(function ($) {
	if ($("body").hasClass("page-reset-password")) {
		$.validator.addMethod(
			"strong_password",
			function (value, element) {
				let password = value;
				if (
					!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%&])(.{8,20}$)/.test(
						password
					)
				) {
					return false;
				}
				return true;
			},
			function (value, element) {
				let password = $(element).val();
				if (!/^(.{8,20}$)/.test(password)) {
					return "Password must be between 8 to 20 characters long.";
				} else if (!/^(?=.*[A-Z])/.test(password)) {
					return "Password must contain at least one uppercase.";
				} else if (!/^(?=.*[a-z])/.test(password)) {
					return "Password must contain at least one lowercase.";
				} else if (!/^(?=.*[0-9])/.test(password)) {
					return "Password must contain at least one digit.";
				} else if (!/^(?=.*[@#$%&])/.test(password)) {
					return "Password must contain special characters from @#$%&.";
				}
				return false;
			}
		);

		function passwordToggle() {
			// Password Toggle
			const togglePassword =
				document.querySelector("#togglePasswordRes") ?? null;
			const password = document.querySelector("#password_res");

			if (togglePassword) {
				togglePassword.addEventListener("click", function () {
					// toggle the type attribute
					const type =
						password.getAttribute("type") === "password" ? "text" : "password";
					password.setAttribute("type", type);

					// toggle the icon
					this.classList.toggle("opacity-20");
				});
			}
		}

		function formValidation() {
			$("#member-password-reset").validate({
				debug: true,
				errorClass: "js-error",
				errorElement: "span",
				errorPlacement: function (error, element) {
					error.addClass("mt-2 text-base text-pink text-opacity-80");
					error.insertAfter(element);
				},
				rules: {
					password: {
						required: true,
						strong_password: true,
					},
				},
				messages: {
					password: {
						required: "Please enter a password",
						minlength: "Please enter at least 8 characters",
					},
				},
			});
		}

		function formSubmit() {
			$("body").on("submit", "#member-password-reset", function (e) {
				e.preventDefault();

				var form = $(this);
				var message = $(".member-password-reset-message");

				$.ajax({
					url: form.attr("action"),
					data: form.serialize(),
					type: form.attr("method"),
					action: "member_password_reset",

					success: function (data) {
						console.log(data);
						if (data.success) {
							message.html(
								'<div class="text-lg mb-6 text-center xl:text-xl xl:mb-8">' +
									data.data.message +
									' <a href="/resources-area#join" class="underline underline-offset-2">Login</a></div>'
							);
						} else {
							message.html(
								'<div class="text-lg mb-6 text-center xl:text-xl xl:mb-8">' +
									data.data.message +
									' <a href="/resources-area">Back to Resources Area</a></div>'
							);
						}
					},
					error: function (xhr, status, error) {
						console.log(xhr);
						alert(status);
						alert(error);
					},
				}).done(function () {});
				return false;
			});
		}

		passwordToggle();
		formValidation();
		formSubmit();
	}
})(jQuery);
